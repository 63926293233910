@font-face {
  font-family: Calibri;
  src: url("/fonts/calibri.eot");
  src: local("☺"), url("/fonts/calibri.woff") format("woff");
}

@font-face {
  font-family: Calibri;
  font-weight: bold;
  src: url("/fonts/calibri-bold.eot");
  src: local("☺"), url("/fonts/calibri-bold.woff") format("woff");
}

.header {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}

body {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}

a {
  color: #3b8bba;
  //  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:visited {
    color: #265778;
  }
}

.small-print {
  font-size: 0.8em;
  margin: 0.6em;
}

.post-meta {
  font-size: 90%;
}

article {
  h3 {
    padding: 0 0 0 0em;
    margin-top: 0;
    font-size: 1.4em;
  }
  p {
    padding: 0;
    margin: 0;
    & + p {
      margin: 1em 0;
    }
  }
  & > footer {
    text-align: right;
  }
}

.author {
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
  img {
    max-width: 500px;
    width: 100%;
    height: auto;
    border-radius: 6px;
    padding-left: 1em;
  }
}

.pure-menu-link,
.pure-menu-heading {
  white-space: normal;
}

.prev-next-post {
  margin-top: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  .next {
    float: right;
    text-align: right;
    overflow: hidden;
  }
  .prev {
    float: left;
    text-align: left;
    overflow: hidden;
  }
}

.recent-projects {
  padding-bottom: 1em;
  a {
    text-decoration: underline;
  }
}

.project {
  border-bottom: 1px solid #ccc;
  margin: 0 0 2em 0;
  padding: 0 0 2em 0;
  &:last-child {
    border-bottom: 0;
  }
  .links {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      margin: 0 1em 0 0;
      padding: 0 0 0 1em;
      border-left: 1px solid #ccc;
      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
    }
    a {
      text-decoration: underline;
    }
  }
  img {
    max-width: 150px;
    height: auto;
    vertical-align: text-top;
    float: left;
    margin: 0 1em 1em 0;
  }
  h3 {
    margin-bottom: 0.1em;
    color: #555;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }
  h4 {
    margin: 0.1em 0 1em 0;
    color: #999;
    font-weight: 100;
  }
}

#menu {
  overflow: visible;
  .brand {
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    font-size: 2em;
    opacity: 0.9;
    letter-spacing: 0.1em;
    text-shadow: 2px 2px #1c4663;
    text-transform: uppercase;
    background: transparent;
    text-decoration: none;
    margin: 0.5em 0;
    padding: 0.5em 0em 0em 1em;
  }
  .pure-menu {
    font-size: 0.9em;
  }
}

i {
  display: inline-block;
  margin-right: 0.2em;
}

.pagination {
  text-align: center;
  margin-top: 3em;
  a {
    color: #265778;
  }
}

.recent-projects {
  h2 {
    clear: both;
    font-size: 2em;
    text-align: center;
  }
}

.technologies {
  list-style: none;
  padding: 0;
  margin: 0.5em 0 0 0;
  font-size: 0.9em;
  li {
    float: left;
    border-left: 1px solid #ccc;
    padding: 0 1em 0 1em;
    &:first-child {
      border-left: 0;
      padding: 0 1em 0 0;
    }
  }
}

.project.os-project {
  margin-bottom: 1em;
  padding-bottom: 1em;
  .os-title {
    float: left;
    margin-right: 0.5em;
  }
  .os-technologies {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline;
      margin: 0;
      padding: 0;
    }
  }
}

img.tiny-logo {
  float: none;
  height: 18px;
  margin: 0;
  vertical-align: text-bottom;
}

.tools {
  margin: 0em 0 3em 0;
  float: left;
  text-align: center;
  width: 100%;
  h2 {
    color: #777;
  }
  ul {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  li {
    height: 100px;
    // float: left;
    width: 80px;
    display: inline-block;
    &:last-child .tool {
      max-width: none;
    }
  }

  .tool {
    vertical-align: middle;
    max-width: 65px;
    width: 100%;
    // max-height: 100px;
    // height: auto;
    margin-right: 1em;
  }
}

.content {
  margin: 0 auto;
  padding: 0 2em;
  max-width: 800px;
  margin-bottom: 0;
  line-height: 1.6em;
  a {
    text-decoration: underline;
  }
}

.header {
  margin: 0;
  color: #333;
  text-align: center;
  padding: 2.5em 2em 0;
  border-bottom: 1px solid #eee;
  h1 {
    margin: 0.2em 0;
    font-size: 2.5em;
    font-weight: 300;
  }
  h2 {
    font-weight: 300;
    color: #ccc;
    padding: 0;
    margin-top: 0;
  }
}

.about {
  margin-top: 1em;
  margin-bottom: 4em;
  h2 {
    text-align: center;
  }
  p {
    text-align: left;
    padding-left: 0;
  }
}

.content-subhead {
  margin: 50px 0 20px 0;
  font-weight: 300;
  color: #888;
}

#drawing {
  float: left;
  position: absolute;
  bottom: 0;
}

#konami {
  color: #a1c5d3;
  border: #1c4663;
}

#react-pong {
  position: fixed;
  bottom: 1em;
  text-align: center;
  padding: 0;
  // left: 0;
  right: 2em;
}

#instructions {
  top: 2px;
  left: 3px;
}

#konami.key-1 span:nth-child(-n + 1),
#konami.key-2 span:nth-child(-n + 2),
#konami.key-3 span:nth-child(-n + 3),
#konami.key-4 span:nth-child(-n + 4),
#konami.key-5 span:nth-child(-n + 5),
#konami.key-6 span:nth-child(-n + 6),
#konami.key-7 span:nth-child(-n + 7),
#konami.key-8 span:nth-child(-n + 8),
#konami.key-9 span:nth-child(-n + 9),
#konami.key-10 span:nth-child(-n + 10),
#konami.key-11 span:nth-child(-n + 11) {
  color: #fff;
}

@media (max-width: 768px) {
  .author {
    img {
      padding-left: 0em;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -ms-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
    }
  }
  .os-title {
    border-right: 0;
  }
  .project {
    .links {
      clear: left;
    }
  }
}

.download-cv-links {
  text-align: center;
}

#cv {
  display: inline-block;
  font-family: Calibri, sans-serif;
  font-size: 0.98em;
  line-height: 1.2;
  margin: 0 auto;
  width: 730px;
  padding: 1em 0 0 0;
  a {
    color: #000;
  }
  h1 {
    font-weight: normal;
  }
  .column.left {
    clear: left;
    float: left;
    width: 250px;
  }
  .column.right {
    clear: right;
    float: right;
    width: 450px;
  }
  section {
    h2 {
      font-size: 1.3em;
      margin: 1em 0 0.5em 0;
    }
    h3 {
      font-size: 1em;
      margin: 0.7em 0 0 0;
    }
    ul {
      margin: 0.2em 0 0 0;
      padding: 0 0 0 1.2em;
      list-style: none;
      li {
        margin: 0;
        padding: 0;
      }
      li:before {
        content: "–";
        display: inline-block;
        margin-left: -1em;
        margin-right: 0.25em;
      }
    }
    #skills ul li:before {
      margin-right: 0.5em;
    }
  }
  .subsection {
    line-height: 1;
  }
  /* helvetica for sub-headers */
  h1 {
    font-family: Helvetica;
    font-size: 1.5em;
    letter-spacing: -1px;
  }
  section h2 {
    color: #4872a9;
    font-family: Helvetica;
    letter-spacing: -0.5px;
    background: #e6eff2;
    padding-left: 5px;
  }
  dl {
    background: #efefef;
  }
  .name {
    font-weight: bold;
    font-size: 2em;
    color: #667;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  #contact dl dt {
    letter-spacing: -1px;
    font-family: Helvetica;
    font-size: 0.9em;
    text-transform: uppercase;
  }
  /* contact styles (labels and text side by side) */
  #contact dl dt {
    clear: left;
    float: left;
    font-weight: bold;
    padding-top: 0;
    margin-right: 0.5em;
    text-align: right;
    width: 4em;
  }
  #content dl dt:first-child {
    padding-top: 3px;
  }
  /* projects styles */
  #projects .subsection ul li {
    line-height: 1.15;
    margin-top: 0.3em;
  }
  #projects .subsection ul li em {
    font-style: normal;
    font-weight: bold;
  }
  #projects .subsection ul li strong a {
    text-decoration: none;
  }
  /* experience styles */
  #experience .subsection h3 {
    font-size: 1.05em;
  }
  #experience .subsection h3 span {
    font-weight: normal;
  }
  #experience .subsection ul li {
    line-height: 1.15em;
    margin-top: 0.2em;
  }
}

@media print {
  .header {
    display: none;
  }
  #side-menu {
    display: none;
  }
  #layout {
    padding: 0;
  }
  #react-pong {
    display: none;
  }
  #layout,
  #menu,
  .menu-link {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  #cv {
    section h2 {
      border-bottom: 1px solid #111;
    }
    .header {
      display: none;
    }
    #side-menu {
      display: none;
    }
    #layout {
      padding-left: 0;
    }
  }
}
